import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MisReportService {

  constructor(
    private http: HttpClient,
    private common: CommonServiceService) { }

  //GET ENTITY DETAILS
  getMISReportData(body) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/misreport/getmisdata?customerid=${body.customerId}&branchid=${body.branchId}&from=${body.startDate}&to=${body.endDate}`);
  }

  getMISFloorReportData(body) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/misreport/flravgdata?customerid=${body.customerId}&branchid=${body.branchId}&from=${body.startDate}&to=${body.endDate}&buildingId=${body.buildingId}&floorId=${body.floorId}`);
  }

  //GET CUSTOMER LIST NOT IN ENTITY

  getCustomerListEntity() {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/approvedCustomerListing?page=0&searchText=&sortBy=&size=100&sortDirection=ASC&status=`);
  }

  //GET BRANCH LIST BY CUSTOMER
  getBranchByCustomer(customerId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/picklist?customerId=${customerId}`);
  }

}
